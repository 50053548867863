import React from "react"
import Layout from "@src/components/Layout"
import SplashWithIllustration from "@src/components/SplashWithIllustration"
import getAppUrl from "@src/utils/getAppUrl"
import Section from "@src/components/Section"
import FeatureGrid from "@src/components/FeatureGrid"
import Text from "@src/components/Text"
import CommunityCallout from "@src/components/CommunityCallout"
import Callout from "@src/components/Callout"
import track from "@src/utils/track"

import { ReactComponent as SocialIntegrationsIcon } from "@src/icons/v2/social-integrations-pintrest.svg"
import { ReactComponent as GoogleSheetsIcon } from "@src/icons/v2/google-sheets-icon.svg"
import { ReactComponent as AsanaIcon } from "@src/icons/v2/asana-icon.svg"
import { ReactComponent as ShopifyIcon } from "@src/icons/v2/shopify-icon.svg"
import { ReactComponent as PlaceholderIcon } from "@src/icons/v2/placeholder-icon.svg"
import OG from "@src/icons/og/OG_Pinterest.jpeg"
import * as styles from "./variation.module.scss"

const SocialIntegrations = ({ location }) => {
  const mainAppUrl = getAppUrl(location, "/dashboard/marketplace/integrations")

  return (
    <Layout
      title="Build workflows and integrate with Pinterest"
      description="Connect Pinterest with thousands of the most popular apps or your API so you can automate your work and have more time for what matters most—no code required."
      url="https://canonic.dev/features/social-integrations"
      location={location}
      ogImage={OG}
    >
      <SplashWithIllustration
        isWhite
        illustrationComponent={<SocialIntegrationsIcon />}
        className={styles.SocialIntegrations}
        heading="Social Integration"
        title="Build workflows and integrate with Pinterest"
        subtitle="Connect Pinterest with thousands of the most popular apps or your API so you can automate your work and have more time for what matters most—no code required."
        buttons={[
          {
            title: "Clone & get started →",
            isPrimary: true,
            href: `${mainAppUrl}`,
            onClick: () => track("Start for free", location.pathname),
          },
        ]}
      />
      <Section blueGradient className={styles.SocialIntegrationsActions}>
        <Text.Heading className={styles.SocialIntegrationsH4}>
          Features
        </Text.Heading>
        <Text.H2 white>Supported Triggers and Actions</Text.H2>

        <FeatureGrid cards className={styles.SocialIntegrationsActionsCards}>
          {[
            {
              title: "New Board",
              description: `Have you ever wondered if your <strong> roadmap should be public? </strong> We think it should! `, //TODO: Add the hyperlinks when they are available
            },
            {
              title: "New Pin",
              description: `Have you ever wondered if your roadmap should be public? We think it should! `, //TODO: Add the hyperlinks when they are available
            },
            {
              title: "Create Pin",
              description: `Have you ever wondered if your roadmap should be public? We think it should! `, //TODO: Add the hyperlinks when they are available
            },
            {
              title: "Increase ROI",
              description: `Have you ever wondered if your roadmap should be public? We think it should! `, //TODO: Add the hyperlinks when they are available
            },
          ]}
        </FeatureGrid>
      </Section>

      <Section white lassName={styles.SocialIntegrationsSamples}>
        <div className={styles.SocialIntegrationsSamplesWrapper}>
          <div className={styles.SocialIntegrationsSamplesWrapperFirst}>
            <Text.Heading>Sample Project</Text.Heading>
            <Text.H2 lightBlueGradient>
              Clone the project built with Pinterest integration or read the
              guide on how to build it
            </Text.H2>
            <FeatureGrid cards white>
              {[
                {
                  icon: PlaceholderIcon,
                  title: "Project 2",
                  description:
                    "Transparency is paramount for SaaS startups. Keep your users informed and engaged by giving them regular updates on your releases.",
                  link: {
                    title: "Clone Project",
                    url: "https://google.com/",
                  },
                },
              ]}
            </FeatureGrid>
          </div>
          <div className={styles.SocialIntegrationsSamplesWrapperSecond}>
            <FeatureGrid cards white>
              {[
                {
                  title:
                    "This is the title of the blog. This is the max length of ",
                  description:
                    "This is the title of the blog. This is the max length of the title to add.",
                  link: { title: "02.05.202 →", url: "https://google.com/" },
                },
              ]}
            </FeatureGrid>
          </div>
        </div>
      </Section>

      <Section lightBlue className={styles.SocialIntegrationsApp}>
        <Text.Heading className={styles.SocialIntegrationsH4}>
          Benefits
        </Text.Heading>
        <Text.H2 lightBlueGradient>Pick an App to pair with</Text.H2>
        <FeatureGrid cards className={styles.SocialIntegrationsAppCards}>
          {[
            {
              icon: GoogleSheetsIcon,
              title: "Google Sheets",
              description: `Spreadsheets  |  Google `,
            },
            {
              icon: AsanaIcon,
              title: "Asana",
              description: `Project Management`,
            },
            {
              icon: ShopifyIcon,
              title: "Shopify",
              description: `Ecommerce Platform`,
            },
            {
              icon: GoogleSheetsIcon,
              title: "Google Sheets",
              description: `Spreadsheets  |  Google `,
            },
            {
              icon: AsanaIcon,
              title: "Asana",
              description: `Project Management`,
            },
            {
              icon: ShopifyIcon,
              title: "Shopify",
              description: `Ecommerce Platform`,
            },
          ]}
        </FeatureGrid>
      </Section>
      <Section black className={styles.SocialIntegrationsBenefits}>
        <Text.Heading className={styles.SocialIntegrationsH4}>
          Benefits
        </Text.Heading>
        <Text.H2 lightBlueGradient>
          Here’s why you should integrate using Canonic
        </Text.H2>
        <FeatureGrid
          vertical
          className={styles.SocialIntegrationsBenefitsCards}
        >
          {[
            {
              icon: PlaceholderIcon,
              title: "Benefit 1",
              description: `The dummy text is going to coming here `,
            },
            {
              icon: PlaceholderIcon,
              title: "Benefit 1",
              description: `The dummy text is going to coming here `,
            },
            {
              icon: PlaceholderIcon,
              title: "Benefit 1",
              description: `The dummy text is going to coming here `,
            },
            {
              icon: PlaceholderIcon,
              title: "Benefit 1",
              description: `The dummy text is going to coming here `,
            },
            {
              icon: PlaceholderIcon,
              title: "Benefit 1",
              description: `The dummy text is going to coming here `,
            },
            {
              icon: PlaceholderIcon,
              title: "Benefit 1",
              description: `The dummy text is going to coming here `,
            },
          ]}
        </FeatureGrid>
      </Section>
      <Callout location={location} title="socialIntegrations" />
      <CommunityCallout />
    </Layout>
  )
}

export default SocialIntegrations
