// extracted by mini-css-extract-plugin
export var SocialIntegrations = "variation-module--SocialIntegrations--1LKYp";
export var SocialIntegrationsActions = "variation-module--SocialIntegrationsActions--V9dJ2";
export var SocialIntegrationsActionsCards = "variation-module--SocialIntegrationsActionsCards--8U9rx";
export var SocialIntegrationsApp = "variation-module--SocialIntegrationsApp--16wY4";
export var SocialIntegrationsAppCards = "variation-module--SocialIntegrationsAppCards--3ZTVg";
export var SocialIntegrationsBenefits = "variation-module--SocialIntegrationsBenefits--32t6A";
export var SocialIntegrationsBenefitsCards = "variation-module--SocialIntegrationsBenefitsCards--1naRa";
export var SocialIntegrationsH4 = "variation-module--SocialIntegrationsH4--2Ms5O";
export var SocialIntegrationsSamples = "variation-module--SocialIntegrationsSamples--2ukug";
export var SocialIntegrationsSamplesWrapper = "variation-module--SocialIntegrationsSamplesWrapper--XJrNU";
export var SocialIntegrationsSamplesWrapperFirst = "variation-module--SocialIntegrationsSamplesWrapperFirst--2lwAC";
export var SocialIntegrationsSamplesWrapperSecond = "variation-module--SocialIntegrationsSamplesWrapperSecond--2xJbf";
export var authentication = "variation-module--authentication--7Giji";
export var authenticationAdditionalBenefit = "variation-module--authenticationAdditionalBenefit--2WWpX";
export var authenticationTopFeatures = "variation-module--authenticationTopFeatures--GZZXh";
export var authenticationTopFeaturesGrid = "variation-module--authenticationTopFeaturesGrid--3VfTe";
export var blogCards = "variation-module--blogCards--3m9W4";
export var blogCardsFigure = "variation-module--blogCardsFigure--27JBx";
export var centerTheHeading = "variation-module--centerTheHeading--XtsmT";
export var centerTheHeadingSDK = "variation-module--centerTheHeadingSDK--4JvUT";
export var centerTheHeadingSDKTopFeatures = "variation-module--centerTheHeadingSDKTopFeatures--3xe6T";
export var cms = "variation-module--cms--2DiPx";
export var cmsBenefits = "variation-module--cmsBenefits--uNhtc";
export var cmsCollaborate = "variation-module--cmsCollaborate--2476C";
export var dataSourcesSplash = "variation-module--dataSourcesSplash--3e7eN";
export var flipOnMobile = "variation-module--flipOnMobile--2KKtt";
export var frontendFrameworks = "variation-module--frontendFrameworks--1ixQ7";
export var gradient = "variation-module--gradient--2YmD9";
export var gradientMask = "variation-module--gradientMask--1fH8O";
export var integrations = "variation-module--integrations--28MMo";
export var multipleDS = "variation-module--multipleDS--1-kIX";
export var multipleDSBenefit = "variation-module--multipleDSBenefit--wpWPZ";
export var multipleDSBenefitBenefits = "variation-module--multipleDSBenefitBenefits--2WB9H";
export var multipleDSBenefitCards = "variation-module--multipleDSBenefitCards--1z4ib";
export var multipleDSBenefitDescription = "variation-module--multipleDSBenefitDescription--3C9cO";
export var multipleDSBenefitSuperscript = "variation-module--multipleDSBenefitSuperscript--1t2fg";
export var multipleDSBenefitTitle = "variation-module--multipleDSBenefitTitle--2dTuw";
export var multipleDSPerk = "variation-module--multipleDSPerk--3dflv";
export var multipleDSPerkCards = "variation-module--multipleDSPerkCards--1GLYi";
export var multipleDSPerkPerks = "variation-module--multipleDSPerkPerks--o9v6J";
export var multipleDSSteps = "variation-module--multipleDSSteps--1EAxn";
export var multipleIntegrationsH4 = "variation-module--multipleIntegrationsH4--3oMZv";
export var multipleIntegrationsIntegrate = "variation-module--multipleIntegrationsIntegrate--jG8pv";
export var multipleIntegrationsIntegrateCards = "variation-module--multipleIntegrationsIntegrateCards--3VD-x";
export var multipleIntegrationsSolution = "variation-module--multipleIntegrationsSolution--1rAZC";
export var multipleIntegrationsSolutionCards = "variation-module--multipleIntegrationsSolutionCards--_S5L2";
export var mvp = "variation-module--mvp--3Se2A";
export var mvpBenefits = "variation-module--mvpBenefits--2dsuS";
export var mvpBenefitsGrid = "variation-module--mvpBenefitsGrid--FKya1";
export var mvpChallenges = "variation-module--mvpChallenges--1EJrK";
export var mvpChallengesText = "variation-module--mvpChallengesText--qJIMP";
export var sassCards = "variation-module--sassCards--20rDt";
export var sassFeatures = "variation-module--sassFeatures--2WhYr";
export var scheduledTriggersAutomate = "variation-module--scheduledTriggersAutomate--ff_Sv";
export var scheduledTriggersBenefits = "variation-module--scheduledTriggersBenefits--1-JOI";
export var scheduledTriggersBenefitsGrid = "variation-module--scheduledTriggersBenefitsGrid--1h0S1";
export var scheduledTriggersTriangle1 = "variation-module--scheduledTriggersTriangle1--3LOXt";
export var scheduledTriggersTriangle2 = "variation-module--scheduledTriggersTriangle2--3_zWA";
export var scrollSlowly = "variation-module--scroll-slowly--2YSDT";
export var scrollSlowly2 = "variation-module--scroll-slowly-2--1q05c";
export var sdkBenefits = "variation-module--sdkBenefits--1WUb1";
export var section = "variation-module--section--2CWcF";
export var sectionFeatures = "variation-module--sectionFeatures--aWFXO";
export var sectionFeaturesGrid = "variation-module--sectionFeaturesGrid--1LkGl";
export var sectionWrapper = "variation-module--sectionWrapper--2dnoW";
export var sectionWrapperDatabase = "variation-module--sectionWrapperDatabase--8r_Aw";
export var sectionWrapperFour = "variation-module--sectionWrapperFour--16Xrw";
export var sectionWrapperIntegrations = "variation-module--sectionWrapperIntegrations--X806Z";
export var sectionWrapperOne = "variation-module--sectionWrapperOne--2IbYk";
export var sectionWrapperPnPGit = "variation-module--sectionWrapperPnPGit--vCes0";
export var sectionWrapperPnPPlugins = "variation-module--sectionWrapperPnPPlugins--2QGb8";
export var sectionWrapperThree = "variation-module--sectionWrapperThree--RrXsa";
export var sectionWrapperTwo = "variation-module--sectionWrapperTwo--3jQ-0";
export var serverlessArch = "variation-module--serverlessArch--I5hNr";
export var showIn = "variation-module--show-in--Zp7LI";
export var templateScroll = "variation-module--templateScroll--2MyUf";
export var templateScrollRow = "variation-module--templateScrollRow--QGpjW";
export var templates = "variation-module--templates--2Cjum";
export var workflow = "variation-module--workflow--XtMPP";
export var workflowTopBenefits = "variation-module--workflowTopBenefits--29sPw";
export var workflowWebhooks = "variation-module--workflowWebhooks--2YhUj";